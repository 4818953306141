export default () => {
  const nav = document.querySelector('.header-nav');
  const trigger = document.querySelector('#nav-trigger');
  const subnavTriggers = document.querySelectorAll('.header-nav-has-subnav');

  const open = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64"><g class="nc-icon-wrapper" stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="#111111" stroke="#111111"><line data-color="color-2" fill="none" stroke-miterlimit="10" x1="4" y1="32" x2="60" y2="32"/> <line fill="none" stroke="#111111" stroke-miterlimit="10" x1="4" y1="14" x2="60" y2="14"/> <line fill="none" stroke="#111111" stroke-miterlimit="10" x1="4" y1="50" x2="60" y2="50"/></g></svg>`;
  const close = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64"><g class="nc-icon-wrapper" stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="#111111" stroke="#111111"><line fill="none" stroke="#111111" stroke-miterlimit="10" x1="54" y1="10" x2="10" y2="54"/> <line fill="none" stroke="#111111" stroke-miterlimit="10" x1="54" y1="54" x2="10" y2="10"/></g></svg>`;

  let visible = false;

  trigger.innerHTML = open;

  trigger.addEventListener('click', e => {
    e.preventDefault();

    if (visible) {
      nav.classList.remove('header-nav--visible');
      trigger.innerHTML = open;
      visible = false;
    } else {
      nav.classList.add('header-nav--visible');
      trigger.innerHTML = close;
      visible = true;
    }
  });

  subnavTriggers.forEach(subnav => {
    const btn = subnav.querySelector('a');
    const styleClass = 'header-nav-has-subnav--visible';

    btn.addEventListener('click', e => {
      e.preventDefault();

      if (subnav.classList.contains(styleClass)) {
        subnav.classList.remove(styleClass);
      } else {
        subnavTriggers.forEach(element => {
          element.classList.remove(styleClass);
          subnav.classList.add(styleClass);
        });
      }
    });
  });
};
