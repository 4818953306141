import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';

import navbar from '../lib/navbar';
import close from '../lib/close';

import '@fortawesome/fontawesome-free/js/all';
import '../styles/main/main.scss';
import '../images';

Rails.start();
Turbolinks.start();

document.addEventListener('turbolinks:load', () => {
  navbar();
  close();
});
