export default () => {
  const buttons = document.querySelectorAll('.delete');

  if (buttons) {
    [].forEach.call(buttons, button => {
      button.addEventListener('click', e => {
        e.preventDefault();
        e.target.parentElement.remove();
      });
    });
  }
};
